@import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,700,800');

@mixin section-title($color:$black, $rule:$black) {
  font-family: 'Muli', Arial, sans-serif !important;
  font-size: rem(40);
  font-style: normal;
  font-weight: $semi-bold;
  color: $color;
  margin-top: 0;

  &::after {
    content: ' ';
    display: block;
    margin: rem(20) auto rem(30) auto;
    width: rem(48);
    height: rem(2);
    background-color: $rule;
  }
}

@mixin section-text($color:$black) {
  color: $color;
  max-width: rem(800);
  margin-left: auto;
  margin-right: auto;
  font-size: rem(18);
  line-height: rem(32);
  font-style: normal;
}

@mixin button-text {
  font-size: rem(16);
  font-weight: $bold;
  color: $black;
}

@mixin teaser-title {
  font-size: rem(22);
  font-weight: $semi-bold;
}

@mixin teaser-subtitle {
  font-size: rem(12);
  font-style: italic;
  margin-top: rem(3);
}

@mixin section-title-left {
  font-weight: $bold;
  font-size: rem(40);
  margin-bottom: rem(30);
  text-align: center;

  @include bp(md){
    text-align: left;
  }
}

@mixin section-text-left {
  max-width: rem(1000);
  font-size: rem(18);
  line-height: rem(30);
}