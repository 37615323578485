// Theme Colors
$blue: #00287d;
$sky: #0193DC;
$denim: #203E5a;
$denim2: #203E5A;
$navy: #142535;
$orange: #f16622;

// Base Colors
$white: #ffffff;
$gray: #EDEEF0;
$gray2: #D8D8D8;
$light-gray: #f4f4f4;
$dark-gray: #4A4A4A;
$black: #000000;

// Breakpoints
$large: 1200px;
$medium2: 1024px;
$medium: 900px;
$medium-max: 899px;
$small: 768px;
$x-small2: 650px;
$x-small: 420px;
$xx-small: 375px;

// Durations
$fast-duration: .2s !default;
$medium-duration: .3s !default;
$slow-duration: .5s !default;
$xslow-duration: 1s !default;

// Dimensions
$container-width: rem(1240);

// Header Dimensions
$header-top-height: 58px;
$header-bottom-desktop-height: 188px;
$header-bottom-laptop-height: 111px;
$header-compact-height: 97px;
$header-mobile-height: 72px;
$header-navbar-height: 60px;
$admin-bar-height: 32px;

// Padding
$desktop-padding: 30px;
$mobile-padding: 20px;
$desktop-vertical-padding: 45px;

// Styles
$primary-gradient: linear-gradient(to bottom, rgba(1,147,220,1) 0%,rgba(67,191,252,1) 100%);
$drop-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);

// Animations
$fast-duration: 0.2s;

// Fonts
$sans-serif-font: 'Muli', Arial,sans-serif;

// Font Weights
$font-weight-light: 300;
$semi-bold: 600;
$bold: 700;




