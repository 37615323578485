@import '../partials';

.contact{
  @include section-padding;
  @include top-border;
  background: $primary-gradient;

  &__boundary {
    @include content-contaner;
  }

  .section-header {
    @include contact-icon;
  }
}