@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin top-border {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100%;
    height: rem(16);
    background-color: $black;
    opacity: 0.4;
  }
}

@mixin section-padding {
  padding-top: rem(60);
  padding-bottom: rem(60);
  padding-right: rem(20);
  padding-left: rem(20);
}

@mixin content-contaner {
  max-width: $container-width;
  margin: 0 auto;
}

@mixin contact-icon {
  &::before {
    content: ' ';
    display: block;
    width: rem(50);
    height: rem(42);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(35);
    background-image: url('../DocumentDefault/Assets/contact-icon.svg');
    background-repeat: no-repeat;
  }
}

@mixin primary-button($bg:$white,$text:$black) {
  @include button-text;
  color: $text;
  text-align: center;
  padding: rem(12) rem(25);
  margin-top: rem(50);
  margin-right: auto;
  margin-left: auto;
  display: table;
  background-color: $bg;
  min-width: rem(200);
  box-shadow: $drop-shadow;
  border-radius: 0;
  transition: background-color 0.3s ease;

  &:hover {
    color: $text;
    background-color: darken($bg,10%);
  }
}

@mixin arrow-link($color:$blue){
  font-size: rem(14);
  color: $color;
  font-weight: $bold;
}


@mixin ratio-box($width, $height){
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: $height / $width * 100%;
  }
  &::after { /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
}

@mixin portrait-image {
  border-radius: 100%;
  border: 4px solid $sky;
}

@mixin search-form() {
  position: relative;
  max-width: 640px;
  margin: 0 auto;

  &__close {
    display: none;
  }

  input[type="text"] {
    //@include text-input;
  }

  button[type="submit"] {
    cursor: pointer;
    position: absolute;
    height: 60px;
    width: 60px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    font-size: rem(20);
    background-color: $blue;
    right: 0;
    top: 0;

    i {
      color: $white;
    }
  }
}


@mixin screen-reader-only() {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}