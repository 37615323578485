@mixin bp($point) {
  @if $point == xxs {
    @media (min-width: $xx-small) {
      @content;
    }
  }
  @if $point == xs {
    @media (min-width: $x-small) {
      @content;
    }
  }
  @if $point == xs2 {
    @media (min-width: $x-small2) {
      @content;
    }
  }
  @if $point == sm {
    @media (min-width: $small) {
      @content;
    }
  }
  @if $point == md-max {
    @media (max-width: $medium-max) {
      @content;
    }
  }
  @if $point == md {
    @media (min-width: $medium) {
      @content;
    }
  }
  @if $point == lg {
    @media (min-width: $large) {
      @content;
    }
  }
}